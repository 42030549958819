#paymentMethods {
  .payment-option {
    .accordion-header {
      .accordion-button {
        min-height: 54px;

        .indicator {
          position: relative;
          display: block;
          margin-right: 10px;
          width: 25px;
          height: 25px;
          background-color: #eeeeee;
          border-radius: 50%;
        }
        &:not(.collapsed) {
          color: $primary;

          .indicator {
            background-color: $primary;

            &:after {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              display: block;
              height: 14px;
              width: 8px;
              border-right: 2px solid #ffffff;
              border-bottom: 2px solid #ffffff;
              transform: rotate(45deg) translate(-50%, -50%);
              transform-origin: top;
            }
          }
        }
      }
    }
    .accordion-collapse {
      .accordion-body {
        .payment_list {
          .payment_type {
            transition: all .3s;

            &.active {
              border-color: $primary !important;
              box-shadow: 0px 0px 0px 3px $primary;
            }
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
    &.no-options {
      .accordion-header {
        .accordion-button {
          &:not(.collapsed) {
            box-shadow: none !important;
          }
          &:after {
            display: none;
          }
        }
      }
      .accordion-collapse {
        .accordion-body {
          padding: 0px !important;
        }
      }
    }
  }
}

form.custom_count {
	.edit-inputs {
		display: flex;
		color: $product-title-link-color;
		> div {
			justify-content: space-evenly;
			height: 28px;
			
			&:nth-child(1) {
				flex: 1 1 24%;
			}
			&:nth-child(2) {
				flex: 1 1 40%;
			}
			&:nth-child(3) {
				flex: 1 1 30%;
			}
			
			button {
				padding: 0 5px;
			}
			
			i {
				color: #c1c1c1;
			}
			
			&.input-group {
				display: grid;
				grid-auto-flow: column;
				.months_info, .btn-number-text {
					font-size: 14px;

					line-height: 27px;
					vertical-align: middle;
				}
			}
			
			&:nth-child(2) {
				border-left: 2px solid lightgray;
				border-right: 2px solid lightgray;
				padding: 0 5px;
			}
			
			&.price-per-month {
				text-align: center;
				line-height: 14px;

				.text-accent {
					font-size: 20px;
					font-weight: 500;
					color: $product-title-link-color!important;
				}
				> div {
					font-size: 11px;
				}
			}
			
		}
	}
}

ul.filters-list {
  li.with-icon {
    position: relative;
    list-style: none;

    i {
      position:absolute;
      margin-left: -20px;
    }
  }
}

#products_add2cart {
  .price-block {
    margin-top: 1rem;

    .product-badge {
      &.product-available {
        top: 0;
      }
    }
    .price_per_month {
      font-size: 12px;
      line-height: 15px;

      .duration-text {}
    }
    .main-features-text-wrp {
      font-size: 16px;
    }
    .terms-wrp-label {
      display: block;
      font-size: 14px;
    }
    .terms_wrp {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: minmax(0,1fr);
      column-width: 1fr;
      height: 48px;
      border: 2px solid #e9e9e9;
      border-radius: 3em;

      .term_block {
        text-align: center;

        .term {
          margin: 0 auto;
          height: 46px;
          width: 46px;
          line-height: 46px;
          font-size: 18px;
          text-align: center;
          cursor: pointer;

          .info-text {
            display: none;
            font-size: 16px;
            text-transform: uppercase;
          }
          &.selected { // , &:hover
            margin-top: -6px;
            height: 56px;
            width: 56px;
            line-height: 56px;
            font-size: 26px;
            background: #fff;
            border-radius: 50%;
            @include box-shadow(0 0 8px rgba(0, 0, 0, .175));

            .text {
              padding-top: 10px;
              line-height: 21px;

              .info-text {
                display: block;
                font-size: 12px;
              }
            }
          }
          &:not(.selected) {
            &:hover {
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

#products_detailed {
  #productPanels {
    .accordion-item {
      &.months-text {
        border-top: 1px solid #dde4eb;
        border-top-left-radius: 0.4375rem;
        border-top-right-radius: 0.4375rem;
      }
    }
  }
  #similarProducts, #recentlyViewedProducts {
    h2 {
      margin-left: 1.375rem;
      margin-right: 1.375rem;
      border-bottom: 1px solid #e6e6e6;
    }
  }
}
@include make-grid-columns-project();

.text-hover-primary:hover {
  color: $primary !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}
.add_to_wishlist {
  .ci-heart-filled {
    display: none;
  }

  &.active {
    .ci-heart {
      display: none;
    }
    .ci-heart-filled {
      display: block;
    }
  }
}

.widget-categories {
  .accordion-header {
    &.no-childs {
      .accordion-button:after {
        display: none;
      }
    }
  }
}

.tags {
  .badge {
    margin-bottom: 4px;
    background-color: var(--badge-color);
    box-shadow: 0 0.5rem 1.125rem -0.275rem var(--badge-color);

    img {
      max-width: 30px;
    }
  }
}

#ajax_loader {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.5);

  .load-icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    width: 66px;
    height: 20px;
    animation: c 1.8s linear infinite;
    -webkit-animation: c 1.8s linear infinite;

    &:before, &:after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      border-radius: 20px;
      background: hsla(0, 0%, 100%, 0.9);
    }
    &:before {
      left: 0;
      animation: d 1.8s linear infinite;
      -webkit-animation: d 1.8s linear infinite;
    }
    &:after {
      right: 0;
      animation: e 1.8s linear infinite;
      -webkit-animation: e 1.8s linear infinite;
      animation-delay: -0.9s;
      -webkit-animation-delay: -0.9s;
    }
  }
}

#searchCarousel {
  @include media-breakpoint-up(md) {
    .product-card {
      max-width: 300px;
    }
  }
}

#contract-view, #all-contracts {
  .summary-blocks-wrp {
    display: grid;
    row-gap: 24px;
  }
  .contract-summary {
    ul {
      margin: 0px;
      padding: 0px;
      list-style: none;

      li {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 20px;
        border-bottom: 1px solid $gray-300;

        span {
          padding: 11px 5px;

          &:nth-child(1) {
            font-weight: 500;
          }
        }
      }
    }
  }
  .contracts-table {
    border-top: 2px solid $gray-700;

    .header-row {
      display: none;
    }
    .contract-rows {
      .contract-row {
        color: $gray-700;

        ul {
          list-style: none;
          display: grid;
          grid-template-areas: "assets assets"
                               "contract_nr status"
                               "date vendor"
                               "view view";
          grid-template-columns: repeat(2, minmax(0, 1fr));
          column-gap: 20px;
          row-gap: 24px;
          margin: 0px;
          padding: 24px 10px;
          border-bottom: 1px solid $gray-300;

          li {
            .field-name {
              display: block;
              font-weight: 500;
            }
            &.assets {
              grid-area: assets;
            }
            &.contract-nr {
              grid-area: contract_nr;
            }
            &.contract-date {
              grid-area: date;
            }
            &.vendor-name {
              grid-area: vendor;
            }
            &.status {
              grid-area: status;

              .badge {
                white-space: initial;
              }
            }
            &.view {
              grid-area: view;
            }
          }
        }
        &:hover {
          text-decoration: none;
        }
        &:nth-child(even) {
          ul {
            background-color: $gray-100;
          }
        }
      }
    }
  }
  .contract-payments-table {
    border-top: 2px solid $gray-700;

    .header-row {
      display: none;
    }
    .payment-rows {
      .payment-row {
        list-style: none;
        display: grid;
        grid-template-areas: "until sum_wt_tax"
                             "tax total"
                             "invoice invoice";
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 20px;
        row-gap: 24px;
        margin: 0px;
        padding: 24px 10px;
        border-bottom: 1px solid $gray-300;

        li {
          .field-name {
            display: block;
            font-weight: 500;
          }
          &.pay-until {
            grid-area: until;
          }
          &.sum-wt-tax {
            grid-area: sum_wt_tax;
          }
          &.tax {
            grid-area: tax;
          }
          &.total {
            grid-area: total;
          }
          &.invoice {
            grid-area: invoice;
          }
        }
        &:nth-child(even) {
          background-color: $gray-100;
        }
      }
    }
  }
  @include media-breakpoint-up(sm) {
    .contract-payments-table {
      border-top: none;

      .header-row {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr)) minmax(80px, 80px);
        column-gap: 20px;
        padding: 10px 10px;
        font-weight: 500;
        border-bottom: 2px solid $gray-700;
      }
      .payment-rows {
        .payment-row {
          grid-template-areas: "until sum_wt_tax tax total invoice";
          grid-template-columns: repeat(4, minmax(0, 1fr)) minmax(80px, 80px);
          column-gap: 20px;

          li {
            display: flex;
            align-items: center;

            .field-name {
              display: none;
            }
            &.invoice {
              .btn {
                span {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    .summary-blocks-wrp {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 16px;
    }
    .contracts-table {
      .contract-rows {
        .contract-row {
          ul {
            grid-template-areas: "contract_nr date vendor status view"
                                 "assets assets assets assets view";
            grid-template-columns: repeat(4, minmax(0, 1fr)) 50px;

            li {
              &.view {
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .summary-blocks-wrp {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
  @include media-breakpoint-up(xl) {
    .summary-blocks-wrp {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}
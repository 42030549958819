.cookie_overlay {
  //position: fixed;
  //top: 0;
  //bottom: 0;
  //left: 0;
  //right: 0;
  //z-index: 10000;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //background: rgba(0, 0, 0, 0.4);

  .cookie_popup {
    position: fixed;
    bottom: 58px;
    left: 50%;
    z-index: 1027;
    height: auto;
    max-width: 98%;
    max-height: calc(100% - 30px);
    width: calc(100% - 30px);
    overflow: auto;
    text-align: center;
    transform: translateX(-50%);
    background-color: $white;
    border: 2px solid $primary;

    .inner-wrp {
      padding: 14px 28px;
      text-align: left;

      h2 {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 1.2;
        color: #231f20;
        text-transform: initial;
        letter-spacing: -0.3px;

        img {
          margin-right: 10px;
          width: 28px;
        }
        i {
          margin-right: 10px;
        }
      }
      p {
        margin: 0px 0px 10px 0px;
        font-size: 14px;
        line-height: 17px;
        text-align: justify;
        color: $dark;
        letter-spacing: -0.4px;

        a {
          font-weight: 700;
          color: $primary;
          text-decoration: none;
        }
      }
      .btn-options {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
        padding: 6px 7px 5px 5px;
        font-weight: 600;
        background-color: $white;
        border: 1px solid $gray-500;
        border-radius: 0px;
        transition: all .3s;

        i {
         margin-right: 10px;
        }
        &:hover, &:active {
          background-color: #eee;
        }
      }
      .btn-agree-all {
        padding: 7px 10px 6px 10px;
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
        color: $white;
        background-color: $primary;
        border: 1px solid $primary;
        border-radius: 0px;
        transition: all .3s;

        &:hover, &:active {
          color: $primary;
          background-color: $white;
        }
      }
      .agree-all-wrp {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      #cookie_popup_more_content {
        margin-top: 20px;
        margin-bottom: 40px;
        padding: 20px;
        border: 2px solid $primary;

        .checkbox-new {
          position: relative;
          display: block;
          margin-top: 10px;
          margin-bottom: 10px;

          input[type="checkbox"] {
            position: absolute;
            opacity: 0;
            z-index: 1;
          }
          label {
            position: relative;
            margin-bottom: 0px;
            padding-left: 30px;
            font-size: 14px;
            line-height: 24px;

            &:before {
              content: "";
              position: absolute;
              top: 2px;
              left: 0;
              display: block;
              width: 20px;
              height: 20px;
              background-color: $white;
              border: 2px solid $primary;
              outline: none;
            }
            &:after {
              content: "";
              position: absolute;
              top: 4px;
              left: 6px;
              display: none;
              width: 8px;
              height: 12px;
              text-align: center;
              border-bottom: 3px solid $dark;
              border-right: 3px solid $dark;
              transform: rotate(45deg);
            }
            &:hover {
              cursor: pointer;
            }
          }
          input[type="checkbox"]:disabled + label {
            &:before {
              background: $gray-300;
            }
            &:hover {
              cursor: not-allowed;
            }
          }
          input[type="checkbox"]:checked + label::after {
            display: inline-block;
          }
          & + p.content {
            padding-left: 30px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .cookie_popup {
      bottom: 0px;

      .inner-wrp {
        .agree-all-wrp {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .btn-options {
            margin-bottom: 0px;

            &:first-child {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}
@import "components/header";

#customers_authorize {
	max-width: 458px;
}

.navbar-tool {
	&.login-btn {
		border: 1px solid $gray-700;
		border-radius: 50em;
		padding: 0 20px 0 10px;
		
		&:hover {
			background: $gray-700;
			.navbar-tool-text, .navbar-tool-icon-box {
				color: #fff;
			}
		}
	}

	@include media-breakpoint-down(lg) {
		&.login-btn {
			padding: 0px;
		}
	}
} 